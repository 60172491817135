<template>
  <div
    id="container-menu"
    :style="{ 'background-color': color_mode }"
    :class="{ 'menu-hide': menuHide }"
  >
    <div class="w-100">
      <b-nav vertical class="w-100 mt-3">
        <b-nav-item :to="{ name: 'agents' }" v-if="rules.includes('agent')">
          <i class="fas fa-id-card-alt"></i>
          Agentes de venda
        </b-nav-item>
        <b-nav-item :to="{ name: 'clients' }" v-if="rules.includes('client')">
          <i class="fas fa-users"></i>
          Clientes
        </b-nav-item>
        <b-nav-item :to="{ name: 'projects' }" v-if="rules.includes('project')">
          <i class="fas fa-file-pdf"></i>
          Pré Projetos
        </b-nav-item>
        <b-nav-item :to="{ name: 'financings' }" v-if="rules.includes('financing')">
          <i class="fas fa-file-invoice-dollar"></i>
          Financiamentos
        </b-nav-item>
        <b-nav-item :to="{ name: 'users' }" v-if="rules.includes('user')">
          <i class="fas fa-user-cog"></i>
          Usuários
        </b-nav-item>
        <b-nav-item :to="{ name: 'admin' }" v-if="rules.includes('admin')">
          <i class="fas fa-cogs"></i>
          Admin
        </b-nav-item>
        <b-nav-item :to="{ name: 'inverter' }" v-if="rules.includes('inverter')">
          <i class="fas fa-charging-station"></i>
          Inversor
        </b-nav-item>
        <b-nav-item :to="{ name: 'panel' }" v-if="rules.includes('panel')">
          <i class="fas fa-solar-panel"></i>
          Painel
        </b-nav-item>
        <b-nav-item :to="{ name: 'transformers' }" v-if="rules.includes('tranformer')">
          <i class="fas fa-microchip"></i>
          Transformador
        </b-nav-item>
        <b-nav-item :to="{ name: 'structure' }" v-if="rules.includes('structure')">
          <i class="fas fa-home"></i>
          Estrutura
        </b-nav-item>
        <b-nav-item :to="{ name: 'components' }" v-if="rules.includes('component')">
          <i class="fas fa-briefcase"></i>
          Componentes
        </b-nav-item>
      </b-nav>
    </div>
  </div>
</template>

<style lang="css" scoped>
#container-menu {
  background: #333;
  position: fixed;
  top: 53px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 270px;
  transition: left ease 0.5s;
}

#container-menu ul li a {
  color: #fff;
}

ul li {
  list-style: none;
}
#container-menu ul li a {
  padding-left: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  transition: all ease 0.5s;
}
#container-menu ul li a:hover {
  padding-left: 20px;
  transition: all ease 0.5s;
}

#container-menu ul li a i {
  width: 32px;
  text-align: center;
  line-height: 24px;
}
.router-link-active {
  background: #ff904d;
}

.azul {
  color: #101c79;
}
.menu-hide {
  left: -320px !important;
  transition: all ease 0.5s;
  transition-delay: 0.5s;
}
</style>

<script>
export default {
  props: ['menuHide'],
  data() {
    return {
      active: false,
      color_mode: process.env.VUE_APP_COLOR_MODE,
      rules: [],
    };
  },
  methods: {
    handleLogout() {
      this.$auth.logout();
    },
  },
  created() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.rules = JSON.parse(user.rules);
  },
};
</script>
